import React, { useContext } from 'react';
import * as S from './TubSelectorDropDown.styles';
import Select, { components } from 'react-select';
import FunnelContext from '../../context/FunnelContext';

const TubSelectorDropDown = props => {
  const {
    currentCategory,
    currentTubIndex,
    setCurrentTubIndex,
    setTubIndex,
    setCurrentTub,
    setCurrentProduct
  } = useContext(FunnelContext);

  const { tubs, builderBlock } = props;

  const options = tubs.map((item, index) => {
    return {
      value: index,
      label: item.title,
      icon: item.image,
      hideLabel: item.hideLabel || false,
      ...item
    };
  });

  const handleSelectChange = item => {
    setCurrentTubIndex(item.value);
    setTubIndex(item.value);
    setCurrentTub(item);
    setCurrentProduct(
      currentCategory === `subscriptions`
        ? `subscription_${item.value}`
        : `onetime_${item.value}`
    );
  };

  const defaultValue = currentTubIndex;

  const { Option, SingleValue } = components;
  const IconOption = props => {
    const { showImage, removeMargin, showTag, tagText } = props.data;
    return (
      <Option {...props}>
        {showImage && (
          <S.IconWrapper>
            <S.Icon
              src={props.data.icon}
              alt={props.data.label}
              imageHeight={props.data.imageHeight}
            />
          </S.IconWrapper>
        )}
        <S.ItemContainer hasIcon={!removeMargin && showImage}>
          {!props.data?.hideLabel && <S.Label>{props.data.label}</S.Label>}
          <S.SubTitle>{props.data.subTitle}</S.SubTitle>
          {showTag && <S.Tag>{tagText}</S.Tag>}
        </S.ItemContainer>
      </Option>
    );
  };

  const SingleValue2 = props => {
    const { showImage, removeMargin, showTag, tagText } = props.data;
    return (
      <SingleValue {...props}>
        {showImage && (
          <S.IconWrapper>
            <S.Icon
              src={props.data.icon}
              alt={props.data.label}
              imageHeight={props.data.imageHeight}
            />
          </S.IconWrapper>
        )}
        <S.ItemContainer hasIcon={!removeMargin && showImage}>
          {!props.data?.hideLabel && <S.Label>{props.data.label}</S.Label>}
          <S.SubTitle>{props.data.subTitle}</S.SubTitle>
          {showTag && <S.Tag className="selected">{tagText}</S.Tag>}
        </S.ItemContainer>
      </SingleValue>
    );
  };

  const customStyles = {
    singleValue: provided => {
      return {
        ...provided,
        width: '100%',
        overflow: 'initial'
      };
    },
    control: provided => {
      return {
        ...provided,
        minHeight: '43px'
      };
    }
  };

  return (
    <S.Container>
      <Select
        styles={customStyles}
        classNamePrefix="tub-selector-dropdown"
        options={options}
        onChange={handleSelectChange}
        inputProps={{ readOnly: true }}
        isSearchable={false}
        defaultValue={options.filter(option => option.value === defaultValue)}
        inputId={`tub-selector-dropdown-${builderBlock.id}`}
        components={{ Option: IconOption, SingleValue: SingleValue2 }}
      />
    </S.Container>
  );
};

export default TubSelectorDropDown;
